<template>
  <LeadRotationBody ref="body" />
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import LeadRotationBody from '@/components/bodies/LeadRotation.vue'

export default {
  mixins: [PageMixin],
  name: 'LeadRotationPage',
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  methods: {
    onSubmit() {}
  },
  components: {
    LeadRotationBody
  }
}
</script>
